.tableWrap {
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.customTable {
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  thead {
    padding: 15px;
    background: white;
    color: white;
  }

  thead tr th {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #c4c4c4;
    z-index: 98;
    color: black;
    text-align: left;
  }

  th {
    padding: 15px 18px !important;
  }

  td {
    padding: 15px 18px !important;
  }

  tr {
    border-bottom: 1px solid #b9b9b9;
    font-size: 0.85em;
  }

  tr:last-child {
    border-bottom: unset;
  }
}
