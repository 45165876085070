.tableWrap {
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.matrixTable {
  table-layout: auto;
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
  border: 2px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  thead tr th {
    position: sticky;
    z-index: 98;
    background-color: #008080;
    color: white;
    text-align: left;
  }

  th,
  td {
    border: 1px solid #bbb;
  }

  th {
    padding: 15px 18px !important;
  }

  td {
    padding: 15px 18px !important;
    color: #222222;
  }

  td:hover {
    background-color: #f8f9fa;
  }

  tr {
    font-size: 0.85em;
  }
}
